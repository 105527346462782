import { notification } from 'antd'
import { 
  all, 
  takeEvery, 
  put, 
  call 
} from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'

const jwts = {
    userProfile: jwt.userProfile,
    updateUserProfile: jwt.updateUserProfile,
    updateUserProfilePic: jwt.updateUserProfilePic,
    updateUserPassword: jwt.updateUserPassword,
    getProfileInputs: jwt.getProfileInputs,
    getProfileBanks: jwt.getProfileBanks,
    getBanksAccount: jwt.getBanksAccount,
    addUserBankDetails: jwt.addUserBankDetails,
    deleteUserCard: jwt.deleteUserCard,
    getAccountStatement: jwt.getAccountStatement,
}

export function* GET_USER_PROFILE() {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.userProfile)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
        data: success.data[0],
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_USER_PROFILE({payload}) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.updateUserProfile, payload)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      }
    })
    notification.success({
      message: "Profile Updated Successfully.",
      description: success.message
    })
    yield put({type: 'profile/GET_USER_PROFILE'})
    yield put({type: 'user/GET_USER_STATUS'})
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: "Profile Update Unsuccessful.",
      description: success.message
      
    })
  }
}

export function* UPDATE_USER_PROFILE_PIC({payload}) {
  const maxSize = 2000000
  const fileSize = payload?.get("profile_pic")?.size
  const isFileSize = (fileSize <= maxSize)
  if (isFileSize) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.updateUserProfilePic, payload)
    if (success.status) {
      yield put({
        type: 'profile/SET_STATE',
        payload: {
          loading: false,
        }
      })
      notification.success({
        message: "Profile Image Updated Successfully.",
        description: success.message
      })
      yield put({type: 'profile/GET_USER_PROFILE'})
    }
    if (!success.status) {
      yield put({
        type: 'profile/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.warning({
        message: "Profile Image Update Unsuccessful.",
        description: success.message
        
      })
    }
  } else return notification.error({
    message: "Error!",
    description: "file size exceeds 2MB, please choose lesser."
  })
}

export function* UPDATE_USER_PASSWORD({payload}) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.updateUserPassword, payload)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      }
    })
    notification.success({
      message: "Password Updated Successfully.",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: "Password Update Unsuccessful.",
      description: success.message
      
    })
  }
}

export function* GET_PROFILE_INPUTS() {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getProfileInputs)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
        inputData: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_PROFILE_BANKS() {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getProfileBanks)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
        bankList: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_BANKS_ACCOUNT({payload}) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      getAccountLoader: true,
    },
  })
  const success = yield call(jwts.getBanksAccount, payload)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        getAccountLoader: false,
        bankAccount: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        getAccountLoader: false,
        bankAccount: {}
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* ADD_USER_BANK_DETAILS({payload}) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.addUserBankDetails, payload)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
        bankDetailsEditable: false
      }
    })
    yield put({type: "user/GET_USER_BANK_DETAILS"})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
        bankDetailsEditable: false
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* DELETE_USER_DEBIT_CARD({payload}) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.deleteUserCard, payload)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "user/GET_USER_BANK_DETAILS"})
    yield put({type: 'user/GET_USER_CARDS'})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* GET_ACCOUNT_STATEMENT({payload}) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAccountStatement, payload)
  if (success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
        savingsAccountStatement: success?.data?.ordinary_savings,
        loanAccountStatement: success?.data?.loan,
        procurementAccountStatement: success?.data?.procurement
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}


export function* HANDLE_FOCUSED_TASK({payload}) {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      focusedTask: payload || "",
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_PROFILE, GET_USER_PROFILE),
    takeEvery(actions.UPDATE_USER_PROFILE, UPDATE_USER_PROFILE),
    takeEvery(actions.UPDATE_USER_PROFILE_PIC, UPDATE_USER_PROFILE_PIC),
    takeEvery(actions.UPDATE_USER_PASSWORD, UPDATE_USER_PASSWORD),
    takeEvery(actions.GET_PROFILE_INPUTS, GET_PROFILE_INPUTS),
    takeEvery(actions.GET_PROFILE_BANKS, GET_PROFILE_BANKS),
    takeEvery(actions.GET_BANKS_ACCOUNT, GET_BANKS_ACCOUNT),
    takeEvery(actions.ADD_USER_BANK_DETAILS, ADD_USER_BANK_DETAILS),
    takeEvery(actions.DELETE_USER_DEBIT_CARD, DELETE_USER_DEBIT_CARD),
    takeEvery(actions.GET_ACCOUNT_STATEMENT, GET_ACCOUNT_STATEMENT),
    takeEvery(actions.HANDLE_FOCUSED_TASK, HANDLE_FOCUSED_TASK),
  ])
}
