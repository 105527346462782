import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'

const jwts = {
    userWallet: jwt.userWallet,
    fundWallet: jwt.fundWallet,
    refundRequest: jwt.refundRequest,
    getPaymentMethod: jwt.getPaymentMethod,
    walletWithdrawal: jwt.walletWithdrawal,
    walletBankWithdrawal: jwt.walletBankWithdrawal,
}

export function* GET_USER_WALLET({payload}) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.userWallet, payload)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
        data: success.data.wallet_balance,
        transactions: success.data.wallet_transactions
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* FUND_USER_WALLET({payload}) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.fundWallet, payload)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "wallet/GET_USER_WALLET"})
    yield put({type: "user/GET_USER_CARDS"})

    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* REQUEST_REFUND({payload}) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.refundRequest, payload)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "wallet/GET_USER_WALLET"})
    yield put({type: "user/GET_USER_CARDS"})

    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* GET_PAYMENT_METHOD() {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getPaymentMethod)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
        paymentMethods: success?.data?.filter(({name})=>!name?.includes("paystack")) || []
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* WITHDRAW_USER_WALLET({payload}) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.walletWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "wallet/GET_USER_WALLET"})
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: true
    })
    notification.success({
      message: "Successful!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* WITHDRAW_USER_WALLET_TO_BANK({payload}) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.walletBankWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "wallet/GET_USER_WALLET"})
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: false
    })
    notification.success({
      message: "Successful!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_WALLET, GET_USER_WALLET),
    takeEvery(actions.FUND_USER_WALLET, FUND_USER_WALLET),
    takeEvery(actions.GET_PAYMENT_METHOD, GET_PAYMENT_METHOD),
    takeEvery(actions.REQUEST_REFUND, REQUEST_REFUND),
    takeEvery(actions.WITHDRAW_USER_WALLET, WITHDRAW_USER_WALLET),
    takeEvery(actions.WITHDRAW_USER_WALLET_TO_BANK, WITHDRAW_USER_WALLET_TO_BANK),
  ])
}
