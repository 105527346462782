import Axios from 'axios'
import apiClient from 'services/axios'
import store from 'store'

export async function login(data) {
  return apiClient
    .post('/auth/login', data)
    .then(response => {
      if (response) {
        const { token } = response?.data
        if (token) {
          store.set('accessToken', token)
        }
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(data) {
  return apiClient
    .post('/auth/signup1', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resendEmail(data) {
  return apiClient
    .post('/auth/resend_emailLink', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function forgotPassword(data) {
  return apiClient
    .post('/auth/recovery', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resetPassword(data) {
  return apiClient
    .post('/auth/reset', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function verifyEmail(id) {
  return apiClient
    .get(`/auth/signupVerifyEmail/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function userDashboard() {
  return apiClient
    .get('/user_dashboard')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserStatus() {
  return apiClient
    .get('/check_status')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function userProfile() {
  return apiClient
    .get('/show_profile')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateUserProfile(data) {
  return apiClient
    .post('/update_profile', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function updateUserProfilePic(data) {
  return apiClient
    .post('/profile_pics', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateUserPassword(data) {
  return apiClient
    .post('/password_change', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function userWallet(data) {
  return apiClient
    .post('/wallet_dashboard', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function switchMembership(data) {
  return apiClient
    .post('/switch_to_civil_servants', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getFileUploadURL(data) {
  return apiClient
    .post('/upload_image', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function addUserBankDetails(data) {
  return apiClient
    .post('/bank_account', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteUserCard(id) {
  return apiClient
    .get(`/wallet/delete_card/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getProfileInputs() {
  return apiClient
    .get('/show_profile1')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getNewToken() {
  return apiClient
    .get('/wallet/refresh_token')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserCards() {
  return apiClient
    .get('/wallet/debit_cards')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function closureEligible() {
  return apiClient
    .get('/closure_eligibility')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function closeZeroAccount() {
  return apiClient
    .post('/closure_application')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function refundRequest(data) {
  return apiClient
    .post('/member_refund_request', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function fundWallet(data) {
  return apiClient
    .post('/fund_wallet', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function viewGuarantors() {
  return apiClient
    .get('/display_loan_requests')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function viewProcureGuarantors() {
  return apiClient
    .get('/display_Procure_requests')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserBankDetails() {
  return apiClient
    .get('/bank_account_details')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTransactionCategory() {
  return apiClient
    .get('/transactions/category')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserTransaction(data) {
  return apiClient
    .post('/transactions', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getMembershipFee() {
  return apiClient
    .get('/get_registration_fee')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function payMembershipFee(data) {
  return apiClient
    .post('/registration_fee_payment', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function getSavingsData() {
  return apiClient
    .get('/savings/dashboard')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function disableAutoSavePlan() {
  return apiClient
    .get('/deactivateAutoSave')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTargetData() {
  return apiClient
    .get('/target/dashboard')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createTargetPlan(data) {
  return apiClient
    .post('/targets/store', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function stopTargetPlan(id) {
  return apiClient
    .get(`/targetsExit/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTargetPlanDetails(id) {
  return apiClient
    .get(`/targets/details/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getSingleTargetPlanDetails(id) {
  return apiClient
    .get(`/targets/transactions/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTargetPlans() {
  return apiClient
    .get(`/targets`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTargetPlanList() {
  return apiClient
    .get(`/active_target_plan`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function targetQuickSave(data) {
  return apiClient
    .post(`/targets/add_funds/${data.id}`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function targetWithdrawal(data) {
  return apiClient
    .post('/target/withdrawal1', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function targetBankWithdrawal(data) {
  return apiClient
    .post('/target/withdrawal2', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createAutoSavePlan(data) {
  return apiClient
    .post('/savings_autoSave', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createQuickSave(data) {
  return apiClient
    .post('/savingsFunds', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function savingsTransaction(data) {
  return apiClient
    .post('/savings/transactions', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getPaymentMethod() {
  return apiClient
    .get('/wallet/payment_options')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAutoSavePlanDetails() {
  return apiClient
    .get('/auto_saveDetails')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getWithdrawEligibility() {
  return apiClient
    .get('/savings_eligibility')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function savingsWithdrawal(data) {
  return apiClient
    .post('/savings_withdrawal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function savingsBankWithdrawal(data) {
  return apiClient
    .post('/savings/withdrawal_to_bank', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function walletWithdrawal(data) {
  return apiClient
    .post('/wallet/withdrawal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function walletBankWithdrawal(data) {
  return apiClient
    .post('/wallet/withdrawal1', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLoanDashboard() {
  return apiClient
    .get('/loan/dashboard')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getProfileBanks() {
  const authorization = "Bearer sk_test_629b392e2345d122b5941f00b27cdd91957ca848"
  return Axios
    .get('https://api.paystack.co/bank', authorization)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getBanksAccount(data) {
  const authorization = "Bearer sk_test_629b392e2345d122b5941f00b27cdd91957ca848"
  return Axios
    .get(
      `https://api.paystack.co/bank/resolve?account_number=${data.account_no}&bank_code=${data.code}`,
      {headers: {
        authorization
      }}
      )
    .then(response => {
      if (response) {
        return response?.data
      }
      return {status: false, message: "user not found"}
    })
    .catch(() => {
      return {status: false, message: "account details not found. check parameters and try again."}
    })
}

export async function getLoanStatus() {
  return apiClient
    .get('/loan/loan_hook')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLoanEligibility() {
  return apiClient
    .get('/loan_eligibility')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function error404() {
  return apiClient
    .get('/auth/404')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// <<<<<<<<<<<<<<<<<<<-------SHARE CAPITAL---------->>>>>>>>>>>>>>>>>
export async function userShare(data) {
  return apiClient
    .post('/share_capitalDashboard', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function fundShare(data) {
  return apiClient
    .post('/add_shareCapital', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function shareWithdrawal(data) {
  return apiClient
    .post('/share/withdrawal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function shareBankWithdrawal(data) {
  return apiClient
    .post('/share/withdrawal1', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getSharePaymentMethod() {
  return apiClient
    .get('/share/payment_options')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createSecurityOTP() {
  return apiClient
    .get('/send_otp')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAccountStatement(data) {
  return apiClient
    .post('/statement_of_account', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function verifySecurityOTP(data) {
  return apiClient
    .post('/verify_otp', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function recoverySecurityOTP(data) {
  return apiClient
    .post('/auth/recovery_otp', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllOrganizations() {
  return apiClient
    .get('/auth/display_companies')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function requestRefund(data) {
  console.log(data)
  return apiClient
    .post('/refund_request', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function accountClosure() {
  return apiClient
    .get('/closure_application')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function initializeZeroPay({ data, params }) {
  return apiClient
    .post('/process_ecommerce_transaction', data, { params })
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTargetFormFee() {
  return apiClient
    .get('/target_fee')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function payTargetFormFee(data) {
  return apiClient
    .post('/target_form_fee', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLoanFormFee() {
  return apiClient
    .get('/loan/loan_fee')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function getAvailableSavingsBalance() {
  return apiClient
    .get('/loan/available_savings_balance')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function getLoanCalculator(data) {
  return apiClient
    .post('/loan/loan_calculator', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function payLoanFormFee(data) {
  return apiClient
    .post('/loan/pay_loan_fee', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function requestForLoan(data) {
  return apiClient
    .post('/loan/add_loan', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function cancelLoanRequest(id) {
  return apiClient
    .get(`/cancel_loan/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllLoans() {
  return apiClient
    .get('/display_loan')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function editLoanDetails({id, data}) {
  return apiClient
    .post(`/edit_loan_request/${id}`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function GurantorAcceptDecline(data) {
  console.log(data)
  return apiClient
    .post(`/acknowledge_decline/${data.id}`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function GurantAcceptDecline(data) {
  console.log(data)
  return apiClient
    .post(`/acknowledge_decline_procure/${data.id}`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLoanDetails(id) {
  return apiClient
    .get(`/loan_request_details/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getProcureDetails(id) {
  return apiClient
    .get(`/procure_request_details/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getGuarantorStatus(id) {
  return apiClient
    .get(`/get_guarantors_status/${id}`, )
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getProcureGuarantorStatus(id) {
  return apiClient
    .get(`/get_procure_guarantors_status/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllLoanDetails(id) {
  return apiClient
    .get(`/loan_full_details/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function payLoanRepayment(data) {
  return apiClient
    .post(`/add_repayment/${data.id}`, data.paymentData)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function loanUserAutocomplete(data) {
  return apiClient
    .get(`/loan/search_guarantor/${data}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function getUserProcurement() {
  return apiClient
    .get(`/loan/procurement_dashboard`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function procurementUserAutocomplete(data) {
  return apiClient
    .get(`/loan/search_guarantor1/${data}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getProcurementFormFee() {
  return apiClient
    .get(`/loan/procurement_fee`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function payProcurementFormFee(data) {
  return apiClient
    .post('/loan/pay_procurement_form_fee', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function requestForProcurement(data) {
  return apiClient
    .post('/loan/add_procurement', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function editProcurementRequest(data) {
  return apiClient
    .post('/loan/edit_procurement', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function specialProcurementRequest(data) {
  return apiClient
    .post('/procurement_registration', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function getProcurementWaiver() {
  return apiClient
    .get('/loan/procurement_duration_waiver')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function getAllProcurements() {
  return apiClient
    .get('/display_procurement')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getSingleProcurementDetails(id) {
  return apiClient
    .get(`/procurement_full_details/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getEditProcurementDetails(id) {
  return apiClient
    .get(`/get_single_procurement/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function cancelProcurement(id) {
  return apiClient
    .get(`/cancel_procurement/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function payProcurementRepayment(data) {
  return apiClient
    .post(`/procurement_repayments/${data.id}`, data.paymentData)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLoanPaymentDuration() {
  return apiClient
    .get(`/loan/loan_repayment_durations`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getProcurementPaymentDuration() {
  return apiClient
    .get(`/loan/procurement_repayment_durations`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
